<template>
  <div class="coo-box">
    <bannerStatic :num="3" />
    <div class="content-box">
       <div class="box" v-for="(item,index) in list" :key="index" :id="'coo'+item.cooperationId">
         <h3 class="content-title">{{item.cooperationdescription}}<b class="line"></b></h3>
         <p class="content-txt">
           {{item.cooperationContent||''}}
         </p>
           <IconFont class="iconstyle" :type="item.cooperationIcon" />
       </div>
    </div>
  </div>
</template>
<script>
import bannerStatic from'@/components/BannerStatic/mBannerStatic'
import IconFont from'@/components/Icon'
import { getCoo } from '@/api/data'
export default {
  name: 'Cooperation',
  components:{
    bannerStatic,
    IconFont,
  },
  data(){
    return {
      list:[]
    }
  },
   mounted() {
    let _this=this
    getCoo().then(res => {
        if (res.state) {
          if(res.data.length>0){
            _this.list=res.data
          }
        }
     })
  },
  methods:{
  },
   beforeDestroy() {
  },
}
</script>
<style lang="less"  >
 .coo-box{
   background: #f7fbfe;
  .content-box{
    min-height: 60vh;
    background: url(/img/cooBg.76dfa02f.png) no-repeat, url(/img/cooBg.76dfa02f.png) no-repeat;
    background-size: 90%;
    background-position: -100% top,400% bottom;
    padding:.666667rem /* 50/75 */ .4rem /* 30/75 */ 0;
    .box{
      width: 100%;
      margin:.533333rem /* 40/75 */ auto;
      min-height: 200px;
      background: #fff;
      padding: .666667rem /* 50/75 */ .4rem /* 30/75 */ .933333rem /* 70/75 */;
      position: relative;
      .iconstyle {
          font-size: 2.666667rem /* 200/75 */;
          position: absolute;
          bottom: .266667rem /* 20/75 */;
          right: 0;
          color: #81b1ea;
          opacity: 0.2;
      }
    }
    .content-title{
      font-size:.453333rem /* 34/75 */;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 1px;
      color: #333333;
      text-align: left;
      position: relative;
      padding-left: .266667rem /* 20/75 */;
      margin-bottom:.266667rem /* 20/75 */;
      .line{
        display: inline-block;
        width: .106667rem /* 8/75 */;
        height: .48rem /* 36/75 */;
        background:#3585e4;
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 0;
        margin-top: -0.24rem;
      }
    }
    .content-txt{
      font-size:.346667rem /* 26/75 */;
      color:#333;
      text-align: left;
      line-height: 2;
      margin-bottom:0;
    }
  }
.ant-anchor-link-active > .ant-anchor-link-title {
    color: #333333;
}
.ant-anchor-link-active{
    border-bottom: 2px solid #1890ff;
}
.ant-anchor-link-active1{
    border-bottom: 2px solid #1890ff;
}
.ant-anchor-ink-ball.visible{
  display: none !important;
}
a,a:visited,a:link{
  text-decoration: none;
  color: #333333;
}
}
</style>